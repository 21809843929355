import { Button, Form, Modal, Typography } from "antd"
import { CustomController } from "./FormControls"
import { PlusOutlined } from "@ant-design/icons"
import { EquipReestrType } from "../../redux/Reducers/equipmentReducer"
import { ProcReestrType } from "../../redux/Reducers/processesReducer"
import { PremReestrType } from "../../redux/Reducers/premisesReducer"
import { SysReestrType } from "../../redux/Reducers/systemsReducer"
import { BaseType } from "antd/es/typography/Base"

const { Text } = Typography

interface PropsType {
    id: string
    objectType: 'equipment' | 'premises' | 'systems' | 'processes'
    myEquipData: EquipReestrType[]
    mySysData: SysReestrType[]
    myProcData: ProcReestrType[]
    myPremData: PremReestrType[]
}

export const MonplansDatesToTasks: React.FC<PropsType> = ({ id, objectType, myEquipData, mySysData, myProcData, myPremData }) => {

    let monplansDate: string | null
    let monplansDate2: string | null
    let type: BaseType = 'secondary'

    if (objectType === 'equipment') {
        monplansDate = myEquipData.find(e => e.idfromtable === id)?.monplansDates?.monplans_date || null
        monplansDate2 = myEquipData.find(e => e.idfromtable === id)?.monplansDates?.monplans_date2 || null
    } else if (objectType === 'premises') {
        monplansDate = myPremData.find(e => e.idfromtable === id)?.monplansDates?.monplans_date || null
        monplansDate2 = myPremData.find(e => e.idfromtable === id)?.monplansDates?.monplans_date2 || null
    } else if (objectType === 'systems') {
        monplansDate = mySysData.find(e => e.idfromtable === id)?.monplansDates?.monplans_date || null
        monplansDate2 = mySysData.find(e => e.idfromtable === id)?.monplansDates?.monplans_date2 || null
    } else {
        monplansDate = myProcData.find(e => e.idfromtable === id)?.monplansDates?.monplans_date || null
        monplansDate2 = myProcData.find(e => e.idfromtable === id)?.monplansDates?.monplans_date2 || null
    }


    const today = new Date()

    if (monplansDate && monplansDate !== '') {
        const [day, month, year] = monplansDate.split('.')
        const formattedDate = new Date(`${year}-${month}-${day}`)

        if (formattedDate <= today) {
            type = 'success'
        } else {
            type = 'secondary'
        }
    }

    if (monplansDate && monplansDate2 && monplansDate2 !== '') {        
        const [day, month, year] = monplansDate.split('.')
        const formattedDate = new Date(`${year}-${month}-${day}`)
        
        const [day2, month2, year2] = monplansDate2.split('.')
        const formattedDate2 = new Date(`${year2}-${month2}-${day2}`)

        if (formattedDate <= today && formattedDate2 >= today) {
            type = 'success'
        } else if (formattedDate2 < today) {
            type = 'danger'
        } else {
            type = 'secondary'
        }
    }
    

    return monplansDate ? monplansDate2 ? <><Text type={type}>С {monplansDate} по {monplansDate2}</Text></> : <Text type={type}>С {monplansDate}</Text> : <Text type="secondary">Сроки не установлены</Text>
}